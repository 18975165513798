import React, {useState, useEffect} from "react";
import {
  LockOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  LoginForm,
  ProConfigProvider,
  ProFormCheckbox,
  ProFormText,
} from '@ant-design/pro-components';
import {Space, Tabs, message, theme} from 'antd';
import "./index.css";
import {useNavigate} from "react-router-dom";
import {useForm} from "antd/es/form/Form";

export interface LoginProps {
  [key: string]: any;
}

const Login: React.FC<LoginProps> = (props: LoginProps) => {

  const navigate = useNavigate();

  const [form] = useForm();

  const {} = props;

  useEffect(() => {
    form.setFieldsValue({
      remember: true
    });
  }, []);

  const {token} = theme.useToken();
  const [loginType, setLoginType] = useState('account');

  const onFinish = () => {
    navigate('overview')
  };

  return (
    <React.Fragment>

      <ProConfigProvider hashed={false}>


        <div className={'h-full'} style={{backgroundColor: token.colorBgContainer}}>

          <LoginForm
            form={form}
            className={'login-form'}
            // backgroundVideoUrl="/videos/login-bg-video.mp4"
            // title="Github"
            // logo="https://github.githubassets.com/favicons/favicon.png"
            // title="Github"
            // subTitle="全球最大的代码托管平台"
            actions={
              <Space>
                {/*其他登录方式*/}
                {/*<AlipayCircleOutlined style={iconStyles}/>*/}
                {/*<TaobaoCircleOutlined style={iconStyles}/>*/}
                {/*<WeiboCircleOutlined style={iconStyles}/>*/}
              </Space>
            }
            onFinish={onFinish}
          >
            <Tabs
              centered
              activeKey={loginType}
              onChange={(activeKey) => setLoginType(activeKey)}
            >
              <Tabs.TabPane key={'account'} tab={'账号密码登录'}/>
              {/*<Tabs.TabPane key={'phone'} tab={'手机号登录'}/>*/}
            </Tabs>

            {loginType === 'account' && (
              <>
                <ProFormText
                  name="username"
                  fieldProps={{
                    size: 'large',
                    prefix: <UserOutlined className={'prefixIcon'}/>,
                  }}
                  placeholder={'username'}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your username!',
                    },
                  ]}
                />
                <ProFormText.Password
                  name="password"
                  fieldProps={{
                    size: 'large',
                    prefix: <LockOutlined className={'prefixIcon'}/>,
                    strengthText:
                      'Password should contain numbers, letters and special characters, at least 8 characters long.',
                    statusRender: (value) => {
                      const getStatus = () => {
                        if (value && value.length > 12) {
                          return 'ok';
                        }
                        if (value && value.length > 6) {
                          return 'pass';
                        }
                        return 'poor';
                      };
                      const status = getStatus();
                      if (status === 'pass') {
                        return (
                          <div style={{color: token.colorWarning}}>
                            强度：中
                          </div>
                        );
                      }
                      if (status === 'ok') {
                        return (
                          <div style={{color: token.colorSuccess}}>
                            强度：强
                          </div>
                        );
                      }
                      return (
                        <div style={{color: token.colorError}}>强度：弱</div>
                      );
                    },
                  }}
                  placeholder={'password'}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter you password！',
                    },
                  ]}
                />
              </>
            )}

            <div style={{marginBlockEnd: 24}}>
              <ProFormCheckbox noStyle name="remember">
                Remember
              </ProFormCheckbox>
              {/*<a style={{float: 'right'}}>忘记密码</a>*/}
            </div>
          </LoginForm>

          <video className="bg-video" autoPlay loop muted>
            <source src="/videos/login-bg-video.mp4" type="video/mp4"/>
          </video>

        </div>

      </ProConfigProvider>

    </React.Fragment>
  );
};

export default React.memo(Login);

