import {useRoutes} from 'react-router-dom';

import Overview from './index.ts';

const OverviewRoutes = () => {
  const routers = useRoutes([
    {
      path: '/',
      element: <Overview/>
    }
  ])
  return routers;
}

export default OverviewRoutes;
