import React, {useState, useEffect, useImperativeHandle, useRef} from "react";
import ServiceGroup from "../../components/services-group";
import {SERVICE_GROUP} from "../../constants/services.tsx";
import './index.scss';

export interface OverviewProps {
  [key: string]: any;
}

const Overview: React.FC<OverviewProps> = (props: OverviewProps) => {

  const {} = props

  useEffect(() => {
  }, []);

  return (
    <React.Fragment>

      <div className={'overview'}>
        <ServiceGroup datasource={SERVICE_GROUP}/>
      </div>

    </React.Fragment>
  );
};

export default React.memo(Overview);

