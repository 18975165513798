import React, {useEffect, useState} from "react";
import {Flex, Input, Radio, Segmented, Switch, theme} from "antd";
import {
  GithubFilled,
  InfoCircleFilled,
  SettingFilled,
  QuestionCircleFilled,
  SearchOutlined
} from "@ant-design/icons";
import {ProCard, ProLayout} from "@ant-design/pro-components";
import {useLocation, useNavigate} from "react-router-dom";
import useSettingStore, {setNetworkType, setSettings} from "../store/setting";
import {useShallow} from "zustand/react/shallow";
import {networkTypeKeys} from "../constants/network";
import SVG from 'react-inlinesvg';
import {ROUTES} from "../constants/services";


export interface LayoutProps {
  [key: string]: any;
}

const Layout: React.FC<LayoutProps> = (props: LayoutProps) => {

  const {pathname} = useLocation();
  const navigate = useNavigate();

  const {children} = props;

  // useShallow(); 对象浅比较, 减少重绘
  const {
    settings: {},
    networkType
  } = useSettingStore(useShallow((state) => state));

  const [themeModel, setThemeModel] = useState(1);

  useEffect(() => {

    setSettings({
      theme: {
        algorithm: !!themeModel ? theme.darkAlgorithm : theme.defaultAlgorithm
      }
    });

    const htmlDom = document.documentElement;
    !!themeModel ?
      htmlDom.classList.toggle('dark') :
      htmlDom.classList.remove('dark');

  }, [themeModel]);

  const networkTypeOnChange = (value: any) => {
    setNetworkType(value);
  };

  const menuOnClick = (item: any) => {
    if (item.path !== '/environment') {
      navigate(item.path || '/overview');
    }
  };

  const actionOnClick = (type: any) => {
    if (type === 'github') {
      window.open('https://github.com/Alpha-CL?tab=repositories');
    }
  };

  const route = {
    path: '/',
    routes: [
      ...ROUTES,
      // props.layout !== 'side' && document.body.clientWidth > 1400 ? {
      //   path: '/environment',
      //   name: (
      //     <Radio.Group value={networkType} onChange={(e) => networkTypeOnChange(e.target.value)}>
      //       <Radio.Button value={networkTypeKeys.intranet}>Intranet</Radio.Button>
      //       <Radio.Button value={networkTypeKeys.extranet}>Extranet</Radio.Button>
      //     </Radio.Group>
      //   ),
      // } : null,
    ],
  };
  const titleOnClick = () => {
    navigate('/overview');
  }

  return (
    <React.Fragment>

      <ProLayout
        // title={<div onClick={titleOnClick}>Alphal Services</div>}
        logo={null}
        fixSiderbar={true}
        layout={'mix'}
        splitMenus={true}

        // appListRender={() => <div>appListRender</div>}
        // menuHeaderRender={() => <div>menuHeaderRender</div>}
        // menuExtraRender={() => <div>menuExtraRender</div>}
        // headerRender={() => <div>headerRender</div>}
        // headerTitleRender={() => <div>headerTitleRender</div>}
        // footerRender={() => <div>footerRender</div>}
        // pageTitleRender={() => <div>pageTitleRender</div>}
        // menuRender={() => <div>menuRender</div>}
        // headerContentRender={() => <div>headerContentRender</div>}

        bgLayoutImgList={[
          {
            src: 'https://img.alicdn.com/imgextra/i2/O1CN01O4etvp1DvpFLKfuWq_!!6000000000279-2-tps-609-606.png',
            left: 85,
            bottom: 100,
            height: '303px',
          },
          {
            src: 'https://img.alicdn.com/imgextra/i2/O1CN01O4etvp1DvpFLKfuWq_!!6000000000279-2-tps-609-606.png',
            bottom: -68,
            right: -45,
            height: '303px',
          },
          {
            src: 'https://img.alicdn.com/imgextra/i3/O1CN018NxReL1shX85Yz6Cx_!!6000000005798-2-tps-884-496.png',
            bottom: 0,
            left: 0,
            width: '331px',
          },
        ]}
        // collapsed={collapsed}
        location={{pathname}}
        menu={{
          type: 'group',
        }}
        avatarProps={{
          src: 'https://gw.alipayobjects.com/zos/antfincdn/efFD%24IOql2/weixintupian_20170331104822.jpg',
          size: 'small',
          title: 'Alphal',
        }}
        actionsRender={(props) => {
          if (props.isMobile) return [];
          return [
            props.layout !== 'side' && document.body.clientWidth > 1400 ? (
              <div
                key="SearchOutlined"
                aria-hidden
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginInlineEnd: 24,
                }}
                onMouseDown={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <Input
                  className={'bg-gray-100 dark:bg-black'}
                  // style={{
                  //   borderRadius: 4,
                  //   marginInlineEnd: 12,
                  //   // backgroundColor: 'rgba(0,0,0,0.03)',
                  // }}
                  prefix={
                    <SearchOutlined
                      style={{
                        color: 'rgba(0, 0, 0, 0.15)',
                      }}
                    />
                  }
                  placeholder="server name"
                  variant="borderless"
                />
              </div>
            ) : undefined,
            <Segmented
              className={''}
              value={themeModel}
              options={[
                {
                  label: <Flex align={'center'}><SVG className={'text-gray-600 dark:text-gray-400'}
                                                     src="/icons/sun.svg"/></Flex>,
                  value: 0
                },
                {
                  label: <Flex align={'center'}><SVG className={'text-gray-600 dark:text-gray-400'}
                                                     src="/icons/moon.svg"/></Flex>,
                  value: 1
                }
              ]}
              onChange={(value) => {
                setThemeModel(value);
              }}
            />,
            <InfoCircleFilled key="InfoCircleFilled"/>,
            <QuestionCircleFilled key="QuestionCircleFilled"/>,
            <GithubFilled key="GithubFilled" onClick={() => actionOnClick('github')}/>,
            <SettingFilled/>
          ];
        }}
        menuFooterRender={(props) => {
          if (props?.collapsed) return undefined;
          return (
            <div
              style={{
                textAlign: 'center',
                paddingBlockStart: 12,
              }}
            >
              <div>© private services</div>
            </div>
          );
        }}
        onMenuHeaderClick={(e) => console.log(e)}
        // onTopMixMenuHeaderClick={(e: any) => console.log(e)}
        menuItemRender={(item, dom) => {
          return (
            <div
              onClick={(e) => {
                menuOnClick(item)
              }}
            >
              {dom}
            </div>
          )
        }}
        route={route}
      >
        {/*<PageContainer>*/}
        <Radio.Group
          className="mb-5"
          value={networkType}
          onChange={(e) => networkTypeOnChange(e.target.value)}
        >
          <Radio.Button value={networkTypeKeys.intranet}>Intranet</Radio.Button>
          <Radio.Button value={networkTypeKeys.extranet}>Extranet</Radio.Button>
        </Radio.Group>
        <ProCard
          style={{
            height: '100%',
          }}
        >
          {children}
        </ProCard>
      </ProLayout>

    </React.Fragment>
  );
};

export default React.memo(Layout);
