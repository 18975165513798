import {useRoutes} from 'react-router-dom';

import Microservices from './index.ts';

const MicroservicesRoutes = () => {
  const routers = useRoutes([
    {
      path: '/*',
      element: <Microservices/>
    }
  ])
  return routers;
}

export default MicroservicesRoutes;

