export const networkTypeKeys = {
  intranet: 'intranet',
  extranet: 'extranet',
}

export const intranetServerKeys = {
  openwrt: 'openwrt',
  macStudio: 'macStudio',
  vmCentos7: 'vmCentos7',
  dellIdrac : 'dellIdrac',
};

export const intranetServerIps = {
  [intranetServerKeys.openwrt]: '192.168.1.1',
  [intranetServerKeys.macStudio]: '192.168.1.120',
  [intranetServerKeys.vmCentos7]: '192.168.1.133',
  [intranetServerKeys.dellIdrac]: '192.168.1.220'
};

export const extranetServerIps = {

};
