import React, {useEffect, useImperativeHandle, ForwardRefRenderFunction, Ref} from "react";
import classNames from "classnames";

export interface TestProps {
  [key: string]: any;
}

interface TestRef {
  [key: string]: any;
}

const Test: ForwardRefRenderFunction<TestRef, TestProps> = (
  props: TestProps,
  ref: Ref<TestRef | HTMLDivElement>
) => {

  const {} = props;

  // Customize instance values exposed to parent components
  useImperativeHandle(ref, () => ({}));

  useEffect(() => {}, []);

  return (
    <React.Fragment>

      <div className={classNames([])}>
        {props.children}
        props: {JSON.stringify(props)}<br/>
      </div>

    </React.Fragment>
  );
};

export default React.forwardRef(Test);
