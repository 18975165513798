import React, {useState, useEffect, useRef} from "react";
import classNames from "classnames";

export interface EditConfigProps {
  [key: string]: any;
}

const EditConfig: React.FC<EditConfigProps> = (props: EditConfigProps) => {

  const {} = props

  useEffect(() => {
  }, []);

  return (
    <React.Fragment>

      <div className={classNames([])}>
        {props.children}
        props: {JSON.stringify(props)}<br/>
      </div>

    </React.Fragment>
  );
};

export default React.memo(EditConfig);
