import React, {useState, useEffect, useImperativeHandle, useRef, useMemo, ForwardRefRenderFunction, Ref} from "react";
import {Button, Divider, Typography} from "antd";
import {getUrlByServer} from "../../constants/services";
import {useShallow} from "zustand/react/shallow";
import useSettingStore from "../../store/setting";
import {networkTypeKeys} from "../../constants/network";
import "./index.scss";

const {Title, Paragraph, Text, Link} = Typography;

export interface ServiceGroupProps {
  [key: string]: any;
}

interface ServiceGroupRef {
  [key: string]: any;
}

const ServiceGroup: ForwardRefRenderFunction<ServiceGroupRef, ServiceGroupProps> = (
  props: ServiceGroupProps,
  ref: Ref<ServiceGroupRef | HTMLDivElement>
) => {

  const {} = props;

  const {
    networkType
  } = useSettingStore(useShallow((state) => state));

  const {datasource} = props;

  const [type, setType] = useState('');

  // Customize instance values exposed to parent components
  useImperativeHandle(ref, () => ({}));

  useEffect(() => {
    setType(networkType);
  }, [networkType]);

  const serverOnClick = (server: any) => {
    let url = '';
    if (networkType === networkTypeKeys.intranet) {
      url = getUrlByServer(server[networkTypeKeys.intranet]);
    } else if (networkType === networkTypeKeys.extranet) {
      url = getUrlByServer(server[networkTypeKeys.extranet]);
    }

    window.open(url);
  };

  const services = useMemo(() => () => {
    return Object.entries(datasource).map(([headline, servicesList]: any, i) => {
      const services = servicesList.map((record: any) => {
        return (<Button
          key={record.label}
          className={'server'}
          onClick={() => serverOnClick(record)}
        >
          {record.label}
        </Button>);
      })
      return (
        <Paragraph key={headline} className={'service-group-section'}>
          <Title className={'headline'} level={5}>{headline}</Title>
          <Paragraph className={'services'}>
            {services}
          </Paragraph>
          {/*{i !== Object.keys(datasource).length - 1 && <Divider/>}*/}
          <Divider/>
        </Paragraph>
      );
    })
  }, [datasource, networkType]);


  return (
    <React.Fragment>

      <Typography className={'service-group'}>
        {services()}
      </Typography>

    </React.Fragment>
  );
};

export default React.forwardRef(ServiceGroup);
