import {useLocation, useRoutes} from "react-router-dom";
import Layout from "./layout";
import {ConfigProvider, theme} from "antd";
import useSettingStore from "./store/setting";
import {useShallow} from "zustand/react/shallow";
import Login from "./pages/login";
import './App.css';
import routes from "./router";

function App() {

  const {pathname} = useLocation();

  const {
    settings,
  } = useSettingStore(useShallow((state) => state));

  const page = useRoutes(routes);

  const defaultSettings = {
    theme: theme.darkAlgorithm
  }

  return (
    <ConfigProvider {...defaultSettings} {...settings}  >
      <div className="App">
        {pathname === '/login'
          ? <Login/>
          : <Layout>{page}</Layout>}
      </div>
    </ConfigProvider>
  );
}

export default App;
