import {useRoutes} from 'react-router-dom';

import EditConfig from './index.ts';

const EditConfigRoutes = () => {
  const routers = useRoutes([
    {
      path: '/',
      element: <EditConfig/>
    }
  ])
  return routers;
}

export default EditConfigRoutes;
