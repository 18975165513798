import {intranetServerIps, intranetServerKeys, networkTypeKeys} from "./network";
import {TagsFilled, WindowsFilled} from "@ant-design/icons";

export const domain = 'alphal.cn';

export const protocol = 'https';

export const port = 9527;

export const baseUrl = `${domain}:${port}`;

export const SERVER_KEYS = {
  docker: 'docker',
  openwrt: 'openwrt',
  jenkins: 'jenkins',
  nexus: 'nexus',
  password: 'password',
  synologyStation: 'synologyStation',
  synologyDrive: 'synologyDrive',
  gpt: 'gpt',
  lobe: 'lobe',
  qinglong: 'qinglong',
  xiaoya: 'xiaoya',
  alist: 'alist',
  prompt: 'prompt',
  wordpress: 'wordpress',
};

export const REDIRECT_SERVER_KEYS = [
  // SERVER_KEYS.docker,
  SERVER_KEYS.openwrt,
  SERVER_KEYS.jenkins,
  SERVER_KEYS.nexus,
  SERVER_KEYS.password,
  SERVER_KEYS.synologyStation,
  SERVER_KEYS.synologyDrive,
];

export const SERVICE_GROUP = {
  services: [
    {
      key: SERVER_KEYS.docker,
      label: 'docker',
      [networkTypeKeys.intranet]: {
        url: "https://192.168.1.133:50001",
      },
      [networkTypeKeys.extranet]: {
        // url: "",
        subDomain: 'docker',
        // port: 50001
      },
    },
    {
      key: SERVER_KEYS.openwrt,
      label: 'openwrt',
      [networkTypeKeys.intranet]: {
        url: "http://192.168.1.1",
      },
      [networkTypeKeys.extranet]: {
        url: 'https://openwrt.alphal.cn:8889',
      },
    },
    {
      key: SERVER_KEYS.jenkins,
      label: 'jenkins',
      [networkTypeKeys.intranet]: {
        serverName: intranetServerKeys.vmCentos7,
        port: 51001
      },
      [networkTypeKeys.extranet]: {
        subDomain: 'jenkins',
        port: 51001
      },
    },
    {
      key: SERVER_KEYS.nexus,
      label: 'nexus',
      [networkTypeKeys.intranet]: {
        serverName: intranetServerKeys.vmCentos7,
        port: 50003
      },
      [networkTypeKeys.extranet]: {
        subDomain: 'nexus',
        // port: 50003
      },
    },
    {
      key: SERVER_KEYS.password,
      label: 'password',
      [networkTypeKeys.intranet]: {
        serverName: intranetServerKeys.vmCentos7,
        port: 50004
      },
      [networkTypeKeys.extranet]: {
        subDomain: 'password',
        // port: 50004
      },
    },
  ],
  synology: [
    {
      key: SERVER_KEYS.synologyStation,
      label: 'synology',
      [networkTypeKeys.intranet]: {
        url: "https://alphal.synology.me:5001/#/signin",
      },
      [networkTypeKeys.extranet]: {
        url: "https://alphal.synology.me:5001/#/signin",
      },
    },
    {
      key: SERVER_KEYS.synologyDrive,
      label: 'drive',
      [networkTypeKeys.intranet]: {
        url: "https://alphal.synology.me:5001/?launchApp=SYNO.SDS.Drive.Application",
      },
      [networkTypeKeys.extranet]: {
        url: "https://alphal.synology.me:5001/?launchApp=SYNO.SDS.Drive.Application",
      },
    },
  ],
  application: [
    {
      key: SERVER_KEYS.gpt,
      label: 'gpt',
      [networkTypeKeys.intranet]: {
        serverName: intranetServerKeys.vmCentos7,
        port: 51002
      },
      [networkTypeKeys.extranet]: {
        subDomain: 'gpt',
        // port: 51002
      },
    },
    {
      key: SERVER_KEYS.lobe,
      label: 'lobe',
      [networkTypeKeys.intranet]: {
        serverName: intranetServerKeys.vmCentos7,
        port: 51003
      },
      [networkTypeKeys.extranet]: {
        subDomain: 'lobe',
        // port: 51003
      },
    },
    {
      key: SERVER_KEYS.qinglong,
      label: 'qinglong',
      [networkTypeKeys.intranet]: {
        serverName: intranetServerKeys.vmCentos7,
        port: 51004
      },
      [networkTypeKeys.extranet]: {
        subDomain: 'qinglong',
        // port: 51004
      },
    },
    {
      key: SERVER_KEYS.xiaoya,
      label: 'xiaoya',
      [networkTypeKeys.intranet]: {
        serverName: intranetServerKeys.vmCentos7,
        port: 51008
      },
      [networkTypeKeys.extranet]: {
        subDomain: 'xiaoya',
        // port: 51008
      },
    },
    {
      key: SERVER_KEYS.alist,
      label: 'alist',
      [networkTypeKeys.intranet]: {
        serverName: intranetServerKeys.vmCentos7,
        port: 50010
      },
      [networkTypeKeys.extranet]: {
        subDomain: 'xiaoya',
        // port: 50010
      },
    },
    {
      key: SERVER_KEYS.prompt,
      label: 'prompt',
      subDomain: 'prompt',
      // port: 50001
    },
    {
      key: SERVER_KEYS.wordpress,
      label: 'wordpress',
      [networkTypeKeys.intranet]: {
        serverName: intranetServerKeys.vmCentos7,
        port: 51007
      },
      [networkTypeKeys.extranet]: {
        subDomain: 'wordpress',
        port: 51007
      },
    },
  ],
};

export const ROUTES = [
  {
    path: '/overview',
    name: 'Links',
    icon: <TagsFilled/>,
    component: './overview',
  },
  {
    path: '/microservices',
    name: 'Iframe',
    icon: <WindowsFilled/>,
    routes: [
      {
        path: '/services',
        name: 'Services',
        routes: [
          {
            path: '/microservices/docker',
            name: 'docker',
          },
          {
            path: '/microservices/jenkins',
            name: 'jenkins',
          },
          {
            path: '/microservices/nexus',
            name: 'nexus',
          },
          {
            path: '/microservices/password',
            name: 'password',
          },
          {
            path: '/microservices/openwrt',
            name: 'openwrt',
          },
          {
            path: '/microservices/synology',
            name: 'synology station',
          },
          {
            path: '/microservices/drive',
            name: 'synology driver',
          },
        ],
      },
      {
        path: '/microservices',
        name: 'Application',
        routes: [
          {
            path: '/microservices/gpt',
            name: 'gpt',
          },
          {
            path: '/microservices/lobe',
            name: 'lobe',
          },
          {
            path: '/microservices/qinglong',
            name: 'qinglong',
          },
          {
            path: '/microservices/xiaoya',
            name: 'xiaoya',
          },
          {
            path: '/microservices/alist',
            name: 'alist',
          },
          {
            path: '/microservices/prompt',
            name: 'prompt',
          },
          {
            path: '/microservices/wordpress',
            name: 'wordpress',
          },
        ],
      },
    ],
  },
];

export const ALL_SERVICES = Object.entries(SERVICE_GROUP).reduce((p: any, [_, v]) => {
  return [...p, ...v];
}, []);

export const getUrlByServer = (server: any) => {
  if (!server) return "";
  const {url, serverName, subDomain, port} = server;
  if (url) {
    return url;
  } else if (serverName && Object.values(intranetServerKeys).includes(serverName) && port) {
    return `http://${intranetServerIps[serverName]}:${port}`;
  } else if (subDomain) {
    return port ? `https://${subDomain}.${domain}:${port}` : `https://${subDomain}.${baseUrl}`;
  }
  return "";
}

export const createAppsByServices = (services: any = ALL_SERVICES) => {
  if (!Array.isArray(services) || services.length === 0) return [];
  return services
    .reduce((p: any, server: any) => {
      const {label, intranet, extranet} = server;
      const intranetApp = {
        name: label,
        url: getUrlByServer(intranet),
      };
      const extranetApp = {
        name: label,
        url: getUrlByServer(extranet),
      };
      return [...p, intranetApp, extranetApp];
    }, [])
    .filter((v: any) => !!v.url && !!v.name);
};

