import {create} from 'zustand';
import {createJSONStorage, persist} from "zustand/middleware";
import {setStoreProperties} from "../utils/zustand";
import {networkTypeKeys} from "../constants/network";

export interface ISettingStore {
  [key: string]: any;
}

export const initialSetting = {
  settings: {
    algorithm: 0
  },
  networkType: networkTypeKeys.extranet
};

// useShallow(); 对象浅比较, 减少重绘
// const {
//  setting,
// } = useSettingStore(useShallow((state: any) => state));
const useSettingStore = create(
  persist<ISettingStore>(
    (set, get) => ({
      ...initialSetting
      // data: [],
      // setData: () => set((state) => ({foo: "bar"})), // 将返回的对象与之前的对象合并
      // getData: () => get().data.map((v: any) => !!v),
    }),
    {
      name: 'settingStore', // unique name
      version: 1,
      storage: createJSONStorage(() => localStorage), // localStorage | sessionStorage | ...
    },
  ),
);

export const setSettingStore = (props: any) =>
  useSettingStore.setState((prev: any) => ({...prev, ...props}));

export const setSettingProperty = (
  key: string,
  value: any,
  merge = true,
  insertBefore = false,
  isDeconstruct = false
) =>
  setStoreProperties(useSettingStore, key, value, merge, insertBefore, isDeconstruct);

export const setSettings = (value: any, merge = true, insertBefore = false, isDeconstruct = false) =>
  setSettingProperty('settings', value, merge, insertBefore, isDeconstruct);

export const resetSetting = () =>
  setSettingProperty('settings', initialSetting.settings, false);

export const setNetworkType = (value: any, merge = true, insertBefore = false, isDeconstruct = false) =>
  setSettingProperty('networkType', value, merge, insertBefore, isDeconstruct);

export const resetSettingStore = () =>
  useSettingStore.setState({...initialSetting});

export const clearSettingStoreStorage = () =>
  useSettingStore.persist.clearStorage();

export default useSettingStore;

