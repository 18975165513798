import {Navigate} from 'react-router-dom';
import Test from "../pages/test";
import OverviewRoutes from "../pages/overview/routes.tsx";
import React from "react";
import MicroservicesRoutes from "../pages/microservices/routes";
import LoginRoutes from "../pages/login/routes";
import EditConfigRoutes from "../pages/edit-config/routes";

const routes = [
  {
    path: '/',
    element: <Navigate to="/overview"/>
  },
  {
    path: '/overview/*',
    element: <OverviewRoutes/>
  },
  {
    path: '/microservices/*',
    element: <MicroservicesRoutes/>
  },
  {
    path: '/login/*',
    element: <LoginRoutes/>
  },
  {
    path: '/editConfig/*',
    element: <EditConfigRoutes/>
  },

  {
    path: '/test',
    element: <Test/>
  }
]

export default routes
