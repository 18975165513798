import React, {useEffect, useMemo} from "react";
import {useLocation} from "react-router-dom";
import useSettingStore from "../../store/setting";
import {useShallow} from "zustand/react/shallow";
import {ALL_SERVICES, getUrlByServer, REDIRECT_SERVER_KEYS} from "../../constants/services.tsx";
import {Button, Result} from "antd";

export interface MicroservicesProps {
  [key: string]: any;
}

const Microservices: React.FC<MicroservicesProps> = (props: MicroservicesProps) => {

  const {pathname} = useLocation();

  const {
    networkType
  } = useSettingStore(useShallow((state) => state));

  useEffect(() => {
    const server = getServer(pathname, networkType);
    if (server.url && REDIRECT_SERVER_KEYS.includes(server.key)) {
      window.open(server.url);
    }
  }, [pathname, networkType]);

  const server = useMemo(() => () => {
    return getServer(pathname, networkType);
  }, [pathname, networkType]);

  const getServer = (pathname: string, networkType: string) => {
    let url = "";
    const [_, serverName] = pathname.split('/').filter(v => !!v);
    const [server] = ALL_SERVICES.filter((v: any) => v.label === serverName);
    if (server && server[networkType]) {
      url = getUrlByServer(server[networkType]);
    }
    return {
      url,
      ...server,
    };
  };

  const openUrl = (url: string) => {
    url && window.open(url);
  };

  return (
    <React.Fragment>

      {!!server().url && !REDIRECT_SERVER_KEYS.includes(server().key)
        ? <iframe src={server().url} style={{width: '100%', height: '100%'}}/>
        : <Result
          className={'mt-12'}
          status="500"
          title="500"
          subTitle="Inline access is not supported."
          extra={<Button className={'w-28'} type="primary" onClick={() => openUrl(server().url)}>Go</Button>}
        />}

    </React.Fragment>
  );
};

export default React.memo(Microservices);

