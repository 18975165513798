import {useRoutes} from "react-router-dom";
import Login from "./index.ts";
import React from "react";

const LoginRoutes = () => {
  const routers = useRoutes([
    {
      path: '/',
      element: <Login/>
    }
  ])
  return routers;
}

export default LoginRoutes;
